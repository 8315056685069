import * as React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Events from "../components/Events";
import Layout from "../components/Layout";
import face from "../images/will-face.jpg";

// markup
const events = [
  {
    name: 'Sharpham Woodland Retreat: Canoeing - Stillness & Flow',
    startDate: '2024-06-10',
    endDate: '2024-06-14',
    location: 'Sharpham House, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/canoeing-woodland-retreats-stillness-flow-4-nights'
  },
  {
    name: 'Sharpham Retreat at Home: 1-day online retreat',
    startDate: '2024-08-31',
    endDate: '2024-08-31',
    location: 'Online (Zoom)',
    link: 'https://www.sharphamtrust.org/calendar/retreat/weekend-retreat-at-home#/events'
  },
  {
    name: 'Sharpham Woodland Retreat: Canoeing - Stillness & Flow',
    startDate: '2024-09-08',
    endDate: '2024-09-12',
    location: 'Sharpham House, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/canoeing-woodland-retreats-stillness-flow-4-nights'
  },
  {
    name: 'Sharpham House: Find Your Peace 5 night silent retreat',
    startDate: '2024-09-20',
    endDate: '2024-09-25',
    location: 'Sharpham House, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/find-your-peace-a-5-night-silent-retreat#/events'
  },
  {
    name: 'Sharpham House: Mindfulness through Singing',
    startDate: '2024-10-01',
    endDate: '2024-10-05',
    location: 'Sharpham House, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/mindfulness-through-singing-uplifting-and-soulful'
  },
  {
    name: 'The Barn: Seven Factors of Awakening',
    startDate: '2024-11-24',
    endDate: '2024-11-30',
    location: 'The Barn Retreat, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/seven-factors-of-awakening-buddhist-retreat'
  },
  {
    name: 'Sharpham House: Life Changes (5 nights)',
    startDate: '2025-02-18',
    endDate: '2025-02-23',
    location: 'Sharpham House, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/life-change'
  },
  {
    name: 'Sharpham House: Mindfulness through Singing',
    startDate: '2025-02-28',
    endDate: '2025-03-04',
    location: 'Sharpham House, Totnes, Devon',
    link: 'https://www.sharphamtrust.org/mindfulness-retreats/details/mindfulness-through-singing-uplifting-and-soulful'
  },
];

const futureEvents = events.filter(e => new Date(e.startDate) >= new Date());

const IndexPage = ({ data, location }) => {

  return (
    <>
      <Helmet title="Will Evans - Meditation Teacher in Totnes, Devon"></Helmet>
      <Layout>
      <section className="overflow-hidden">
        <Container>
          <Row>
            <Col xs={12} lg={7}>
              <div className="intro py-5 pe-4">
                  <div className="intro-face d-flex justify-content-center float-md-start">
                    <img src={face} className="me-4 mb-4" width={300} height={292}/>
                  </div>
                  <h3 className="lead">
                    I teach meditative practices to help people connect to their inner resources of steadiness, clarity, strength and joy.
                  </h3>
                  <p>
                    My own meditation journey began over a decade ago, in an attempt to find some space from an incessantly busy mind.
                  </p>
                  <p>
                    Since then, meditation has grown to play many different roles in my life: mental hygiene, self-therapy, spiritual practice, inquiry into the nature of experience, and a way to cultivate presence and compassion.
                  </p>
                  <p>
                    I aim to teach meditation in ways that reflect the diversity of ways of practicing and the diversity of personalities of meditators, while honouring the core principle of the path of meditation - to be here to experience life more fully and more freely.
                  </p>
                  <p>
                    I am inspired by many contemplative traditions and approaches - Buddhism (in several flavours, but particularly Insight Meditation/Vipassana), contemporary Mindfulness, and the Non-dual and Yogic traditions.
                  </p>
                  <p>I lead retreats and courses online and in Devon, and I also offer one-to-one guidance for your meditation practice. <a href="mailto:willje1986@gmail.com">Send me an email</a> if you would like to connect with me.</p>
              </div>

            </Col>
            <Col xs={12} lg={5} className="position-relative events-container p-4 pb-5 mb-lg-0">

              <h3 className="mb-4">Upcoming Retreats, Courses &amp; Workshops</h3>
              <Events events={futureEvents} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-eggshell py-4 bg-cover bg-dead-tree">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="bg-overlay p-4">
                <h3>1:1 Meditation Guidance</h3>
                <p>Personalised meditation guidance and support for your meditation practice. Perhaps you:</p>
                <ul>
                  <li>Want support and accountability to establish a daily practice</li>
                  <li>Are unsure if you're making the most of the time you spend meditating</li>
                  <li>Are interested in the deeper possibilities of meditation</li>
                  <li>Find meditation very difficult and want help to make it more easeful</li>
                </ul>
                <Button href="https://calendly.com/willevansmeditation/meditation-guidance">Schedule a session</Button>
              </div>
            </Col>
            {/* <Col xs={12} md={6}>
              <div className="bg-overlay p-4">
                <h3>From my blog</h3>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </Layout>
    </>
   
  )
}

export default IndexPage;